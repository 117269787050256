import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Cta from '../components/cta'
import AltSection from '../components/columnAlternating'
import CardIcons from '../components/cardIcons.js'
import Card from '../components/card' 
import CarouselQuote from '../components/carouselQuote'
import OfficeLocations from '../components/columnAlternatingOffices'
import Faqs from '../components/faqs'

const ServiceangebotTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.openingpara,
                }}
              />
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>    

     {post.frontmatter.section &&
          <section className="bg-light-blue">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 pb-5 mt-xl-0 title-xs-medium title-large" >
                Our services
              </h2>
                {post.frontmatter.section.map((sections, index) => (
                  <AltSection
                    key={index}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    description={sections.description}
                    image={sections.image.childImageSharp.gatsbyImageData}
                    alt={sections.alttext}
                    placement={sections.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pt-0 title-xs-medium title-medium text-very-dark-blue"
                    link={sections.btnlink}
                    colour={sections.btncolour}
                  />
                  ))}
            </MDBContainer>
          </section>
        }


        {(post.frontmatter.faq && post.frontmatter.faq.length >= 1) && 
          <section className="bg-white mt-n5">
            <MDBContainer>
              {post.frontmatter.faq && <Faqs items={post.frontmatter.faq} />}
            </MDBContainer>
          </section>
        }        

      {post.frontmatter.bragroll && 
        <section className="bg-light-blue">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.bragroll.title}
              </h2>
              {post.frontmatter.bragroll.card && (
                <MDBRow>
                  <>
                    {post.frontmatter.bragroll.card.map((cards, index) => (
                      <CardIcons
                        key={index}
                        collg="4"
                        colmd="6"
                        title={cards.title}
                        subtitle={cards.subtitle}
                        image={cards.image.childImageSharp.gatsbyImageData}
                        alt={cards.alttext}
                      />
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      }        

      {post.frontmatter.locations && (
        <section className="bg-white" id="locations">
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large pb-5">
                {post.frontmatter.locations.title}
              </h3>
              {post.frontmatter.locations.offices.map((officelocations, index) => {
                  return (
                    <OfficeLocations
                      key={index}
                      office={officelocations.office}
                      address={officelocations.address}
                      tel={officelocations.tel}
                      email={officelocations.email}
                      lat={officelocations.lat}
                      lng={officelocations.lng}
                      placement={officelocations.placement}
                    />
                  )
                }
              )}
            </MDBContainer>
        </section>      
      )} 

      {post.frontmatter.quote && 
        <CarouselQuote data={post.frontmatter.quote} />
      }                

      {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>

                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="9rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alttext={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}          

      {post.frontmatter.cta && (
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      )}
    </Layout>
  )
}
export default ServiceangebotTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "serviceangebot" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext         
        section {
          title
          subtitle
          placement
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          alttext
          btnlink
          btncolour
        }      
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        locations {
          title
          offices {
            office
            address
            tel
            email
            lat
            lng
            placement
          }
        }           
        quote {
          strapline
          author
        }    
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }                     
        cta {
          title
          description
          linktext
          link
        }
        faq {
          question
          answer
        }        
        template
      }
    }
  }
`
